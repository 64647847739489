import React from 'react';

import { graphql } from 'gatsby';

import Hero from 'components/hero';
import Feature from 'components/feature';
import IconList from 'components/icon_list';
import CTA from 'components/cta';

const ServiosPessoaisTemplate = (props) => {
  //PROPS
  const {
    data: {
      page: {
        hero: { title, text, subtitle },
        corporate_services: { featureBanner, pros },
        cta,
      },
    },
  } = props;
  //PROPS

  return (
    <main className="main">
      <Hero data={{ title, subtitle, text }} center />
      {featureBanner.map((feature, index) => {
        return <Feature {...feature} key={index} />;
      })}
      <IconList data={pros} />
      <CTA data={cta} />
    </main>
  );
};

export const query = graphql`
  query ServicosPessoais($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      hero {
        title
        text
        subtitle
      }
      corporate_services {
        featureBanner {
          featureTitle
          featureText
          featureSubtitle
          featureImageFirst
          featureImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 1.154929577464789
                )
              }
            }
          }
        }
        pros {
          recruIcon
          recruText
          recruTitle
        }
      }
      cta {
        ctaSubtitle
        ctaTitle
        type
        ctaCta {
          url
          title
          target
        }
        ctaImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
export default ServiosPessoaisTemplate;
